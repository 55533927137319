/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { NavLink } from 'react-router-dom';

//bs
import { Container, Row, Col } from 'reactstrap';

//images
import SignatureImg from 'css/images/r-signature.svg';
import LogoInsta from 'css/images/instagram.svg';
import LogoMail from 'css/images/mail.svg';

const Footer = () => {
	return (
		<Container>
			<Row>
				<Col className="mx-auto" md="8">
					<div className="footer-layer">
						<div className="footer-sign">
							<img src={SignatureImg} alt="Rocío González" />
						</div>
						<div className="redes">
							<a
								href="https://instagram.com/rociogonzalezevents"
								target="_blank"
								rel="noreferrer"
							>
								<img src={LogoInsta} alt="Instagram" />
							</a>

							<NavLink to="/contacto">
								<img src={LogoMail} alt="Mail" />
							</NavLink>
						</div>
						<div className="info">
							<p>© 2024 Rocío González - Todos los derechos reservados.</p>
							<p>Diseño web ~ Mateagenciacreativa</p>
						</div>
						<div className="bodas-net">
						<a
							rel="nofollow noreferrer"
							href="https://www.bodas.net/organizacion-bodas/rocio-gonzalez-events-planner--e245576"
							target="_blank"
							title="Bodas.net"
						>
							<img
								alt="Bodas.net"
								src="https://www.bodas.net/images/sellos/Sello-bodas-peq--pp245576.png"
								style={{ borderWidth: 0 }}
							/>
						</a>
					</div>
					</div>
					
				</Col>
			</Row>
		</Container>
	);
};

export default Footer;
