import React, { useState, useEffect } from 'react';

//Enrutado
import { Route } from 'react-router-dom';
import ReactGA from 'react-ga';

//cookies
import CookieConsent from 'react-cookie-consent';

//Loader
import LoadingLayer from './loaders/LoadingLayer';

//Componentes
import Navigation from './common/Navigation';
import Footer from './common/Footer';

const Layout = ({ component: Component, noCont, isAdmin, ...props }) => {
	const [isLoading, setIsLoading] = useState(false);

	ReactGA.initialize('G-5Y4H9658T4');

	useEffect(() => {
		props.path && ReactGA.pageview(props.path);
	}, [props]);

	const appDataFake = {
		home: {
			momento:'Hay determinados momentos en nuestra vida que adquieren una relevancia insólita, convirtiéndose en páginas señaladas de nuestra historia.\n\nEn Rocio González Events Planner nos dedicamos, apasionadamente, a hacer inolvidables esos momentos.Creemos en la importancia de dejar una huella imborrable en cada experiencia que organizamos.\n\nNos comprometemos a entender las necesidades y deseos de nuestros clientes de manera integral, trabajando estrechamente en colaboración con ellos y asegurándonos de que cada detalle sea cuidadosamente considerado y ejecutado con precisión.',
			soyroci: '¡Hola! Soy Rocío González y me encantaría ser parte de vuestra historia.\n\nSé que aún no me conocéis, así que quiero contaros un poco sobre mí, qué me apasiona y quién está detrás de la mente creativa TIME TO BE.\n\nEstudié Publicidad y Relaciones Públicas en la Universidad de Valladolid, y más tarde me especialicé en Marketing Digital, Comunicación y Redes Sociales. Mi curiosidad y ganas de seguir creciendo me llevaron a explorar el fascinante mundo de los eventos, dedicando más de 120 horas de formación en Protocolo y Wedding Planner, porque para mí, los pequeños detalles marcan la diferencia y me encanta asegurarme de que cada momento especial sea inolvidable.\n\nCada evento que planifico es una nueva historia que contar. Si quieres conocer más sobre mi trayectoria, mis experiencias y cómo podemos hacer que tus momentos sean realmente memorables, '
		},
		blog: {
			mainTx:
				'En el podréis encontrar ideas, tips, y tendencias para ayudaros a organizar tus eventos más importantes con estilo, desde el estilismo, hasta protocolos, pasando por las últimas tendencias en menaje de mesas y decoración en general.',
			posts: [
				{
					id: 1,
					title: 'Flores en tendencia para primavera y verano',
					img: '/images/trends/1.jpg',
				},
				{
					id: 2,
					title: 'Ideas para decorar tu boda con estilo',
					img: '/images/trends/2.jpg',
				},
				{
					id: 3,
					title: 'Tendencias en menaje de mesas para eventos',
					img: '/images/trends/3.jpg',
				},
				{
					id: 4,
					title: 'Cómo organizar una fiesta temática',
					img: '/images/trends/4.jpg',
				},
				{
					id: 5,
					title: 'Ideas para decorar tu boda con estilo',
					img: '/images/trends/5.jpg',
				},
				{
					id: 6,
					title: 'Tendencias en menaje de mesas para eventos',
					img: '/images/trends/6.jpg',
				},
			],
		},
		momentos: {
			mainTx:
				'Estos son algunos de los momentos que más hemos disfrutado al hacerlos reales.\n\n¿Quieres formar parte de nuestra historia?',
			gallery: [
				{ id: 1, title: 'Boda', img: '/images/momentos/n1.jpg' },
				{ id: 2, title: 'Bautizo', img: '/images/momentos/n2.jpg' },
				{ id: 3, title: 'Comunión', img: '/images/momentos/m6.jpg' },
				
				{ id: 4, title: 'Aniversario', img: '/images/momentos/m2.jpg' },
				{ id: 5, title: 'Cumpleaños', img: '/images/momentos/4.jpg' },
				{ id: 6, title: 'Fiesta', img: '/images/momentos/n3.jpg' },
				{ id: 7, title: 'Evento', img: '/images/momentos/n4.jpg' },
				{ id: 8, title: 'Celebración', img: '/images/momentos/m5.jpg' },
				{ id: 9, title: 'Fiesta', img: '/images/momentos/n5.jpg' },
				{ id: 10, title: 'Fiesta', img: '/images/momentos/n8.jpg' },
				{ id: 11, title: 'Fiesta', img: '/images/momentos/n7.jpg' },
				{ id: 12, title: 'Fiesta', img: '/images/momentos/n6.jpg' },
				{ id: 13, title: 'Fiesta', img: '/images/momentos/n9.jpg' },
				{ id: 15, title: 'Fiesta', img: '/images/momentos/n11.jpg' },
				{ id: 14, title: 'Fiesta', img: '/images/momentos/n10.jpg' },
				
				
				
			],
		},
		contacto: {
			mainTx:
				'Queremos ayudaros a hacer memorables todos y cada uno de los momentos que conforman vuestra historia y que vosotros sólo os preocupéis de disfrutar. Escucharemos todas vuestras ideas, hasta aquellas que os parezcan una locura.',
		},
		servicios: {
			mainTx:
				'Creemos en la importancia de dejar una huella imborrable en cada experiencia que organizamos. Por ello, ofrecemos diferentes opciones para adaptarnos a las necesidades de cada historia.',
			servicio1:'¿Ya os habéis decidido por dar el paso más importante y no sabéis por dónde empezar? No os preocupéis. En ROCIO GONZÁLEZ EVENTS PLANNER nos encargaremos de todo: Buscaremos con mimo los proveedores que mejor se adapten a tus gustos, estilo y presupuesto, seleccionándolos cuidadosamente y asegurándonos de que participéis activamente en la elección final. Además, crearemos un proyecto de diseño que transmita vuestra esencia y que garantice una armonía perfecta, desde las invitaciones hasta el ramo. Y, por supuesto, supervisamos cada detalle el gran día, para asegurarnos de que todo sea sencillamente perfecto y que vosotros solo os preocupéis de disfrutar del gran momento.',
			servicio2:'Para parejas que han llevado a cabo por su cuenta propia toda la organización previa a la boda, pero necesitan ayuda para que todas esas decisiones se coordinen de manera eficaz y fluyan con normalidad en el gran día. Para ello, crearemos una escaleta del evento detallada, contactaremos y nos familiarizaremos con cada proveedor contratado y coordinaremos cada aspecto para que todo se desarrolle según lo planeado. No dejaremos nada al azar. Estaremos a tu disposición desde el comienzo del enlace hasta que tenga lugar el primer baile, asegurándonos de que todo se desarrolla según lo planeado cumpliendo con vuestras expectativas.',
			servicio3:'Si solo pensar por donde tienes que empezar a organizar y coordinar te abruma o si ya tienes todo organizado, pero hay un pequeño detalle que no te deja dormir, estas tutorías son perfectas para ti. Durante 45 minutos hablaremos de todo lo que os preocupa, aportando soluciones creativas, guiándoos y haciéndoos más amable el camino hacia vuestro gran momento.',
			servicio4:'Cada momento de nuestra vida cuenta una historia. Y nosotros queremos formar parte de la vuestra, adaptándonos de manera creativa a tus necesidades y poniendo toda nuestra ilusión y corazón para crear momentos únicos. Cuéntanos tu idea y deja que te sorprendamos.',
		},
		about: {
			mainTx:
				'Conoce mi historia y descubre más sobre mi proyecto.\n\nÚnete conmigo en esta emocionante aventura y creemos juntos momentos que dejen huella.',
			hello:'Me encantaría poder formar parte de vuestra historia. Como aún no me conocéis, quiero compartir con vosotros quién soy, qué hago en este mundo y cómo es la persona que está detrás de la mente creativa TIME TO BE.\n\nEstoy graduada en Publicidad y Relaciones Públicas por la Universidad de Valladolid. Además, he perfeccionado mi trayectoria profesional con un máster en Marketing Digital, Comunicación y Redes Sociales por la Universidad Menéndez Pelayo. Mis ganas de seguir creciendo en el sector me llevaron a especializarme en el mundo de los eventos, respaldada por varios cursos especializados de más de 120 horas en Protocolo y Wedding Planner, con el fin de potenciar mis habilidades de gestión, planificación y pensamiento estratégico. Sin embargo, si tuviésemos que hablar de tú a tú, te diría que me encanta la organización creativa y el cuidado de los pequeños detalles para conseguir que los momentos importantes que forman parte de las historias de las personas sean únicos y perduren en su recuerdo y en el de sus seres queridos.\n\nMi viaje profesional ha estado marcado por roles clave en agencias creativas de renombre como Comunica + A, donde he gestionado como supervisora de cuentas a clientes como Orange, IATA, Mercedes, entre otros. En estos proyectos, además de desarrollar mi creatividad, he nutrido una especial habilidad en la organización meticulosa y la planificación estratégica, participando en el desarrollo de campañas publicitarias y eventos corporativos de diferentes envergaduras, adquiriendo gran capacidad de adaptación y resolución de conflictos en situaciones de crisis.\n\nAhora, en este proyecto personal y junto a mi equipo, canalizo toda mi experiencia y pasión por los eventos, ayudando a crear momentos personalizados y memorables. Mi enfoque se basa en entender las historias como únicas y transformarlas en recuerdos inolvidables. Queremos hacer historia contigo, ',
		},
	};

	return isLoading ? (
		<LoadingLayer visible={isLoading} hideAll />
	) : (
		<div className="main-layout">
			<CookieConsent
				location="bottom"
				buttonText="Aceptar"
				style={{ background: '#fff', color: '#000' }}
				buttonStyle={{ color: '#fff', background: '#000', fontSize: '12px' }}
				expires={150}
			>
				Utilizamos cookies de terceros para analizar y mejorar tu navegación. Si
				continuas navegando, consideramos que aceptas su uso. Puedes cambiar la
				configuración u obtener más información en nuestra política de cookies.
			</CookieConsent>

			<div className="main-navigation">
				<Navigation />
			</div>

			<div className="main-container">
				<Route
					render={(properties) => (
						<Component {...props} appData={appDataFake} />
					)}
				/>
			</div>

			<div className="main-footer">
				<Footer />
			</div>
		</div>
	);
};

export default Layout;
