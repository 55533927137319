// import React from 'react';

// //bs
// import { Col, Row } from 'reactstrap';

// const Reviews = () => {
// 	const reviewList = [
// 		{
// 			foto: 'images/opiniones/op1.png',
// 			imagen: 'images/opiniones/par1.png',
// 			valoracion: 'star-5.png',
// 			nombre: 'MARYAM BAHÍA',
// 			opinion:
// 				'Si alguna vez imaginamos el día de nuestra boda, sin duda, nunca hubiera sido tan perfecto como lo que conseguiste tú y todo tu equipo, Rocio. Fue todo, no sólo la ayuda en el proceso, sino la tranquilidad y seguridad que nos transmitisteis en todo momento, implicándoos de manera incondicional en el proyecto. El día de la boda en ningún momento tuvimos nervios ni preocupación por nada. Estábamos seguros de que todo sería exactamente como fue, ¡PERFECTO! Muchos besos',
// 		},
// 		{
// 			foto: 'images/opiniones/op2.jpg',
// 			imagen: 'images/opiniones/par2.jpg',
// 			valoracion: 'star-5.png',
// 			nombre: '',
// 			opinion: '',
// 		},
// 		{
// 			foto: 'images/opiniones/op3.jpg',
// 			imagen: 'images/opiniones/par3.jpg',
// 			valoracion: 'star-5.png',
// 			nombre: '',
// 			opinion: '',
// 		},
// 		{
// 			foto: 'images/opiniones/op4.jpg',
// 			imagen: 'images/opiniones/par4.jpg',
// 			valoracion: 'star-5.png',
// 			nombre: '',
// 			opinion: '',
// 		},
// 		{
// 			foto: 'images/opiniones/op5.jpg',
// 			imagen: 'images/opiniones/par5.jpg',
// 			valoracion: 'star-5.png',
// 			nombre: '',
// 			opinion: '',
// 		},
// 	];

// 	const randomReviews = reviewList.sort(() => Math.random() - 0.5);

// 	return (
// 		<Row>
// 			<Col md="9" className="mt-5 mb-5 mx-auto">
// 				<div className="box-comments">
// 					{randomReviews.map((review, index) => (
// 						<Row key={index} className="align-items-center">
// 							<Col md="4">
// 								<div className="photo">
// 									<img
// 										src={`/images/opiniones/${review.imagen}`}
// 										alt="Rocío González Events"
// 										className="img-fluid"
// 									/>
// 								</div>
// 							</Col>
// 							<Col md="8">
// 								<div className="comment">
// 									<div className="pic">
// 										<img
// 											src={`/images/opiniones/${review.foto}`}
// 											alt={review.nombre}
// 										/>
// 									</div>
// 									<div className="text">
// 										<div className="name">{review.nombre}</div>
// 										<div className="starts">
// 											<img
// 												src={`/images/opiniones/${review.valoracion}`}
// 												alt="estrellas"
// 											/>
// 										</div>
// 										<div className="opi-tx">{review.opinion}</div>
// 									</div>
// 								</div>
// 							</Col>
// 						</Row>
// 					))}
// 				</div>
// 			</Col>
// 		</Row>
// 	);
// };

// export default Reviews;

import React from 'react';
import {
	Row,
	Col,
	Carousel,
	CarouselItem,
	CarouselIndicators,
} from 'reactstrap';

const Reviews = () => {
	const reviewList = [
		{
			foto: 'op1.png',
			imagen: 'par1.png',
			valoracion: 'star-5.png',
			nombre: 'MARYAM B.',
			opinion:
				'Si alguna vez imaginamos el día de nuestra boda, sin duda, nunca hubiera sido tan perfecto como lo que conseguiste tú y todo tu equipo, Rocio. Fue todo, no sólo la ayuda en el proceso, sino la tranquilidad y seguridad que nos transmitisteis en todo momento, implicándoos de manera incondicional en el proyecto. El día de la boda en ningún momento tuvimos nervios ni preocupación por nada. Estábamos seguros de que todo sería exactamente como fue, ¡PERFECTO! Muchos besos',
		},
		{
			foto: 'op2.jpg',
			imagen: 'par2.jpg',
			valoracion: 'star-5.png',
			nombre: 'FELIX U.',
			opinion:
				'Desde el principio, mi mujer y yo sabíamos que necesitábamos a una gran profesional para organizar nuestra boda. Contratar a Rocío fue la mejor decisión. A pesar de la distancia (Asturias – Madrid), siempre estuvo dispuesta a ayudarnos y se adaptó a nuestras necesidades asturianas.\nNos ofreció excelentes proveedores ajustados a nuestro presupuesto y su atención al detalle nos brindó tranquilidad. El día de la boda, todo salió perfecto gracias a su gestión y dedicación. Su empatía y conexión personal fueron clave para confiarle uno de los días más importantes de nuestras vidas. ¡Sin duda, repetiríamos con ella!',
		},
		{
			foto: 'op3.jpg',
			imagen: 'par3.jpg',
			valoracion: 'star-5.png',
			nombre: 'LAURA B.',
			opinion:
				'El 6/9/24 celebramos nuestra boda y Rocío y su ayudante nos acompañaron en este día. Al principio de contratar el servicio no sabíamos si merecería la pena o no y si de verdad podríamos estar despreocupados el día de la boda, pero que acierto fue contar con Rocío ese día!! Desde el minuto uno que contactamos con ella fue súper atenta, cercana y profesional! La boda salió de 10, atentas a todo y poniendo todo con un cariño y mimo verdaderamente especial! Te damos las gracias de corazón ❤️',
		},
		{
			foto: 'op4.jpg',
			imagen: 'par4.jpg',
			valoracion: 'star-5.png',
			nombre: 'MARÍA ELINA V.',
			opinion:
				'Nos casamos en Madrid el 24 de febrero de 2024, y confiamos en Rocio para la organización de nuestra boda. El resultado fue mejor de lo que habíamos soñado. Todos los invitados nos dieron la enhorabuena y quedaron maravillados. Fue una boda preciosa y repleta de detalles para todos. Si estás pensando en contratar una wedding planner, sin duda, Rocio es la persona. Su sensibilidad y dedicación te envolverán desde el primer minuto. Muchas gracias por todo Rocio, no podemos estar más felices.',
		},
		{
			foto: 'op5.jpg',
			imagen: 'par5.jpg',
			valoracion: 'star-5.png',
			nombre: 'MARÍA L.',
			opinion:
				'Sin duda contar con Rocío fue el gran acierto de nuestra boda! Conectamos desde el primer momento y nos aportó la seguridad que necesitábamos, ya que es una persona super atenta y dedicada.\nFue nuestra guía en todo momento, hizo que la preparación fuese super fluida y nos dio miles de ideas que encantaron a los invitados.\nEl día de la boda todo salió perfecto gracias a ella. Es una gran profesional. Volveríamos a elegirle mil veces más! Sin duda, fue nuestra mejor decisión :)',
		},
	];

	const [activeIndex, setActiveIndex] = React.useState(0);
	const [animating, setAnimating] = React.useState(false);

	const next = () => {
		if (animating) return;
		const nextIndex =
			activeIndex === reviewList.length - 1 ? 0 : activeIndex + 1;
		setActiveIndex(nextIndex);
	};

	const previous = () => {
		if (animating) return;
		const nextIndex =
			activeIndex === 0 ? reviewList.length - 1 : activeIndex - 1;
		setActiveIndex(nextIndex);
	};

	const goToIndex = (newIndex) => {
		if (animating) return;
		setActiveIndex(newIndex);
	};

	const slides = reviewList.map((review, index) => {
		return (
			<CarouselItem
				onExiting={() => setAnimating(true)}
				onExited={() => setAnimating(false)}
				key={index}
			>
				<div className="box-comments">
					<Row className="align-items-center">
						<Col md="4">
							<div className="photo">
								<img
									src={`/images/opiniones/${review.imagen}`}
									alt="Rocío González Events"
									className="img-fluid"
								/>
							</div>
						</Col>
						<Col md="8">
							<div className="comment">
								<div className="pic">
									<img
										src={`/images/opiniones/${review.foto}`}
										alt={review.nombre}
									/>
								</div>
								<div className="text">
									<div className="name">{review.nombre}</div>
									<div className="starts">
										<img
											src={`/images/opiniones/${review.valoracion}`}
											alt="estrellas"
										/>
									</div>
									<div className="opi-tx">{review.opinion}</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</CarouselItem>
		);
	});

	return (
		<Row className='pb-5'>
			<Col md="12" lg="9" className="mt-5 mb-5 mx-auto slider-reviews">
				<Carousel activeIndex={activeIndex} next={next} previous={previous} interval={15000}>
					<CarouselIndicators
						items={reviewList}
						activeIndex={activeIndex}
						onClickHandler={goToIndex}
					/>
					{slides}
				</Carousel>
			</Col>
		</Row>
	);
};

export default Reviews;
